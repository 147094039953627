<template>
  <div class="block-el">
    <doughnut-chart
      v-if="fetched && carriers.labels.length"
      :chartdata="carriers"
      :options="options"
      :height="163"
    />
    <div v-else-if="fetched" class="text-center m-t-100">
      <i class="fas fa-chart-pie font-size-80"></i>
      <h5 class="card-title m-t-20">{{$t('wpp-component.none')}}</h5>
    </div>
    <div v-else class="qt-block-ui" />
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import DoughnutChart from '../DoughnutChart.vue';

export default {
  name: 'CarriersChart',
  components: { DoughnutChart },
  props: ['days'],
  data() {
    return {
      fetched: false,
      carriers: {},
      options: {
        responsive: true,
      },
    };
  },
  watch: {
    days: 'fetchCarriers',
  },
  created() {
    this.fetchCarriers();
  },
  methods: {
    fetchCarriers() {
      this.fetched = false;
      SmsService.carriers(this.days)
        .then(
          (response) => {
            console.log('SMS report');
            console.log(response);
            this.carriers = response;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 200px;
}
</style>
