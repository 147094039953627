<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <span class="col-lg-6 col-md-6 col-sm-6 m-t-5 d-inline-block"
          >{{$t('sms.dashboard.lbl-recent-msg')}}</span><!-- Mensagens Recentes -->
        <div class="col-lg-6 col-md-6 col-sm-6 text-right text-left-m">
          <router-link class="btn btn-primary" to="/sms/history"
            >{{$t('sms.dashboard.lbl-show-history')}}</router-link><!-- Histórico Completo -->
        </div>
      </div>
    </div>
    <div class="card-body block-el">
      <div v-if="loaded" class="responsive-table">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{$t('sms.dashboard.recent-msg.lbl-dt')}}</th><!-- Data -->
              <th scope="col">{{$t('sms.dashboard.recent-msg.lbl-type')}}</th><!-- Tipo -->
              <th scope="col">{{$t('sms.dashboard.recent-msg.lbl-sender')}}</th><!-- Remetente -->
              <th scope="col">{{$t('sms.dashboard.recent-msg.lbl-recipient')}}</th><!-- Destinatario -->
              <th scope="col">{{$t('sms.dashboard.recent-msg.lbl-msg')}}</th><!-- Mensagem -->
              <th scope="col">{{$t('sms.dashboard.recent-msg.lbl-parts')}}</th><!-- Partes -->
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="message in messages"
              :key="message.id"
              :to="`history/${message.id}`"
              tag="tr"
            >
              <td data-label="Data">
                <div>{{ message.created_at }}</div>
              </td>
              <td data-label="Tipo">
                <template v-if="message.direction == 'outbound'"
                  >{{$t('sms.dashboard.type-status.lbl-send')}}</template><!-- Envio -->
                <template v-else-if="message.direction == 'inbound'"
                  >{{$t('sms.dashboard.type-status.lbl-received')}}</template><!-- Recebido -->
              </td>
              <td data-label="Remetente">{{ message.from }}</td>
              <td data-label="Destinatário">{{ message.to }}</td>
              <td
                data-label="Mensagem"
                class="max-200"
                v-tooltip.top="`${message.body}`"
              >
                {{ message.body }}
              </td>
              <td data-label="Pertes">{{ message.segments }}</td>
              <td data-label="Status">
                <span
                  v-if="message.status == 'accepted'"
                  class="badge badge-light"
                  >{{$t('sms.dashboard.status.lbl-pending')}}</span><!-- Pendente -->
                <span
                  v-else-if="message.status == 'queued'"
                  class="badge badge-secondary"
                  >{{$t('sms.dashboard.status.lbl-queue')}}</span> <!-- Em Fila -->
                <span
                  v-else-if="message.status == 'sending'"
                  class="badge badge-accent"
                  >{{$t('sms.dashboard.status.lbl-sending')}}</span><!-- Enviando -->
                <span
                  v-else-if="message.status == 'sent'"
                  class="badge badge-info"
                  >{{$t('sms.dashboard.status.lbl-sent')}}</span><!-- Enviado -->
                <span
                  v-else-if="message.status == 'failed'"
                  class="badge badge-danger"
                  >{{$t('sms.dashboard.status.lbl-failure')}}</span><!-- Falha -->
                <span
                  v-else-if="message.status == 'delivered'"
                  class="badge badge-success"
                  >{{$t('sms.dashboard.status.lbl-delivered')}}</span><!-- Entregue -->
                <span
                  v-else-if="message.status == 'undelivered'"
                  class="badge badge-dark"
                  >{{$t('sms.dashboard.status.lbl-unavailable')}}</span><!-- Indisponivel -->
                <span
                  v-else-if="message.status == 'received'"
                  class="badge badge-success"
                  >{{$t('sms.dashboard.status.lbl-response')}}</span><!-- Resposta -->
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
      <div v-else class="qt-block-ui" />
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';

export default {
  name: 'RecentCard',
  data() {
    return {
      loaded: false,
      messages: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      SmsService.getMessages({
        limit: '10',
      }).then(
        (messages) => {
          this.loaded = true;
          this.messages = messages;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.card-body {
  min-height: 300px;
}
tr {
  cursor: pointer;
  &:hover {
    background-color: #fafafa; /* #f0f6ff */
  }
}
</style>
